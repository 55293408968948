import {PageLayout} from "@components";
import {PartnersV2} from "../../landingpagev2/_components/_Partners/_Partners";
import {JoinInstitutionsV2} from "../../landingpagev2/_components/_JoinInstitutionsV2/_JoinInstitutionsV2";
import React from "react";
import {Demo, GetWorkDone} from ".././biopharmaceutical";
import FeatureCard, {FeatureCardLayout, FeatureCardProps} from "@components/FeatureCard/FeatureCard";
import {StaticImage} from "gatsby-plugin-image";
import * as styles from "../../products/orders/orders.module.scss";
import Margins from "@components/Margins/Margins";

export default function Research () {
    const demoProps = {
        title: "Laboratory automation software designed for clinical research",
        subtitle: "Genemod's lab automation platform is recognized for its reliability by top clinical research organizations.",
        srcImg: "person-holding-silver-black-hand-tool.png",
        disableBackgroundShapes: true,
    }

    const cards: FeatureCardProps[] = [
        {
            title: "Optimize time and budget efficiencies",
            list: [
                "Expedite each stage in clinical research, achieving faster trial completion and decision-making.",
                "Leverage Genemod for streamlined budgeting in clinical research.",
                "Promote active collaboration within teams through real-time communication and sharing of data.",
            ],
            image: (
                <StaticImage
                    src={"../../../assets/images/landing_page/products_inventory.png"}
                    alt="Easily Manage and Submit Orders"
                />
            ),
        },
        {
            title: "Genemod streamlines clinical research",
            list: [
                "Laboratory information management system",
                "Electronic lab notebook",
                "AI platform",
            ],
            sublist:[
                "Genemod’s LIMS offers a unified platform for managing laboratory data, including research data, sample tracking, test results, quality control, and data analysis.",
                "Efficiently store, organize, and document research data and reports. Facilitate team collaboration on a centralized platform, minimizing time spent on redundant processes.",
                "Genemod integrates ChatGPT in the electronic lab notebook, enabling report revisions at a click and providing instant information through our Genemod AI chat bot.",
            ],
            image: (
                <StaticImage
                    src={"../../../assets/products/single-source-of-truth.png"}
                    alt="Collaborate for In-depth Order Tracking"
                />
            ),
            orientation: "right",
        },
    ];

    const getWorkDoneContent = {
        title: "Centralize all research processes within a single, comprehensive software solution",
        subtitle: "Enhance clinical research breakthroughs with Genemod's integrated approach to data management.",
        skewTop: false,
        skewBottom: false,
        showSquares: false,
        showCentralizedPlatformV2: true,
        showSubtitleBottom: true,
    }


    const footerContent = {
        headerText: "Powered by Genemod",
        subheaderText: "Our Enterprise plans help companies ensure data integrity and improve collaboration institution-wide. Each subscription is acustom-built, expert-driven foundation of your lab automation program.",
    }

    return (
        <PageLayout
            seoOptions={{ type: "PREDEFINED", pageName: "research" }}
        >
            <Margins>
            <Demo {...demoProps}/>
            <FeatureCardLayout className={styles.cards}>
                {cards.map((card, index) => {
                    return <FeatureCard {...card} key={index} />;
                })}
            </FeatureCardLayout>
            <GetWorkDone type={"white"} {...getWorkDoneContent}/>
            <PartnersV2 includeHighPerformer />
            <JoinInstitutionsV2 {...footerContent} />
            </Margins>
        </PageLayout>
    );
}